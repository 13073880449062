<template>
    <AppPaginatedGrid
        ref="grid"
        :showActionItems="false"
        :service="service"
        permission="estrutura_mail"
        newPath="/mail/new"
        editPath="/mail/"
        :expander="false"
        subtitle="E-mail"
        tooltip="Relatório de Envio de E-mail"
        nomeTelaDoManual="mail-list"
    >
        <template #columns>
            <Column field="destino" header="Destinatario" :sortable="true" sortField="destino"></Column>
            <Column field="mensagem" header="Título" :sortable="true" sortField="mensagem"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="createdAt">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="status" header="Status" :sortable="true" sortField="status"></Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/mail');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
